
import { Component } from 'vue-property-decorator'
import DataFormPageView from '@/frame/share/data-form-page.vue'
import StateDataFormPage from '@/frame/share/state-form-page'
import CollapsePanel from '@/components/collapse-panle.vue'
import TeamUseApi, { TeamUse, TeamUserEvent } from '@/api/team-user'
import StateFormItem from '@/components/state-formitem.vue'
import StateButton from '@/components/state-formbutton.vue'

@Component({
  name: 'dataPage',
  components: { DataFormPageView, CollapsePanel, StateFormItem, StateButton }
})
export default class DataPage extends StateDataFormPage<TeamUse, any> {
  protected serverState=''

  /**
   * 当前表单数据
   */
  protected formData: TeamUse={

  }

  created () {
    this.initFromState({})
  }

  /**
   * 加载指定id的表单数据
   */
  protected onLoadFormData (id: number): Promise<TeamUse> {
    return new Promise<TeamUse>((resolve, reject) => {
      TeamUseApi.getItemById(id)
        .then((response) => {
          console.log('加载')
          resolve(response.data!)
        })
        .catch((err: Error) => reject(err))
    })
  }

  /**
   * 生成新增的表单数据
   */
  protected onCreateNewFormData (): Promise<TeamUse> {
    console.log('初始新')
    return new Promise<TeamUse>((resolve, reject) => {
      resolve({
        id: undefined,
        userCode: '',
        nameAtTeam: '未命名'
      })
    })
  }

  /**
   * 执行新增数据保存
   */
  protected onInsertFormData (): Promise<TeamUse> {
    console.log('新增')
    return new Promise<TeamUse>((resolve, reject) => {
      TeamUseApi.insertItem(this.formData)
        .then((response) => resolve(response.data!))
        .catch((err: Error) => reject(err))
    })
  }

  /**
   * 保存修改数据
   */
  protected onSaveFormData (): Promise<TeamUse> {
    console.log('保存')
    return new Promise<TeamUse>((resolve, reject) => {
      TeamUseApi.updateItem(this.formData)
        .then((response) => resolve(response.data!))
        .catch((err: Error) => reject(err))
    })
  }

  /**
   * 拒绝申请
   */
  rejectRequest () {
    console.log('拒绝请求')
    this.$Modal.confirm({
      title: '确认',
      content: '确认要拒绝申请吗?',
      onOk: () => {
        this.loading = true
        TeamUseApi.updateWithEvent(this.formData.id!, TeamUserEvent.Reject)
          .then((response) => {
            this.formData = response.data!
            this.rawData = response.data
          }).catch((err: Error) => {
            this.$Notice.error({
              title: '错误',
              desc: `保存数据失败：${err.message}`
            })
          }).finally(() => { this.loading = false })
      }
    })
  }

  /**
   * 同意申请
   */
  agreementRequest () {
    this.$Modal.confirm({
      title: '确认',
      content: '确认要同意申请吗?',
      onOk: () => {
        this.loading = true
        TeamUseApi.updateWithEvent(this.formData.id!, TeamUserEvent.Agreement)
          .then((response) => {
            this.formData = response.data!
            this.rawData = response.data
          }).catch((err: Error) => {
            this.$Notice.error({
              title: '错误',
              desc: `保存数据失败：${err.message}`
            })
          }).finally(() => { this.loading = false })
      }
    })
  }
}
